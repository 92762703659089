<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Content-->
  <div class="custom-container">
    <!--    Page Title-->
    <page-title v-if="editEmployee.firstName">
      <template #title>
        <span class="h4 text-primary"
          >Edit
          <span class="text-primary-dark"
            >{{ editEmployee.firstName + " " + editEmployee.lastName }}'s</span
          >
          details</span
        >
      </template>
      <template #button>
        <div class="">
          <button
            class="btn btn-primary btn-sm"
            @click="
              employeeBookLeave(
                editEmployee.id,
                editEmployee.firstName + ' ' + editEmployee.lastName
              )
            "
          >
            <font-awesome-icon class="mr-1" icon="plus-circle" />
            Book Leave
          </button>
        </div>

        <!--        check if the data is loaded-->
        <span
          v-if="
            editEmployee.firstName &&
            canDeactivate(editEmployee.role, editEmployee.id)
          "
          class="ml-1"
        >
          <a
            v-if="editEmployee.isActive"
            class="btn btn-sm btn-danger"
            @click.prevent="inActiveEmployee(editEmployee.id)"
          >
            <font-awesome-icon class="mr-1" icon="ban" />
            Deactivate
          </a>
          <a
            v-if="!editEmployee.isActive"
            class="btn btn-sm btn-primary"
            @click.prevent="reActiveEmployee(editEmployee.id)"
          >
            <font-awesome-icon class="mr-1" icon="history" />
            Reactivate
          </a>
        </span>

        <span>
          <button
            class="btn btn-sm btn-secondary ml-1"
            @click="this.$router.back()"
          >
            <font-awesome-icon class="mr-1" icon="arrow-left" />
            Go Back
          </button>
        </span>
      </template>
    </page-title>

    <template v-else>
      <div class="card bg-light shadow-sm py-3">
        <loading-component flex></loading-component>
      </div>
    </template>

    <template v-if="!loading">
      <!--      profile Image-->
      <div class="row g-0 pb-2 align-items-center">
        <div class="col-2">
          <profile-image
            :is-editable="editEmployee.id == authId"
            :image="editEmployee.profileImage"
            :gender="editEmployee.gender"
            :employee-id="editEmployee.id"
          ></profile-image>
        </div>

        <div class="col-10">
          <card-row :avatar="false" margin="mb-0">
            <!--        Details-->
            <template class="py-4" v-if="editEmployee.firstName" #details>
              <!--            Row 1: Name and status-->
              <div class="d-md-flex align-items-center">
                <!--            Name-->

                <div class="font-weight-bold h5 my-0 mr-2">
                  {{ editEmployee.firstName }} {{ editEmployee.lastName }}
                </div>

                <div class="">
                  <span class="badge badge-pill badge-primary">
                    {{ editEmployee.jobTitle || "" }}
                  </span>
                </div>

                <div v-if="editEmployee.id == authId" class="ml-1">
                  <div :class="`badge badge-pill badge-dark`">It's Me</div>
                </div>
              </div>

              <!--            Row 3: Department-->
              <div class="d-md-flex align-items-center mt-1">
                <div class="mr-3">
                  <span class="mr-1">Department: </span>
                  <span class="badge badge-secondary">
                    {{ editEmployee.departmentName }}
                  </span>
                </div>
                <div class="">
                  <span class="mr-1">Role: </span>
                  <span class="badge badge-secondary">
                    {{ editEmployee.role }}
                  </span>
                </div>
              </div>

              <!--            Row 5: Email-->
              <div class="d-md-flex align-items-center mt-1">
                <div class="mr-3">
                  <span class="text-primary">
                    <font-awesome-icon class="mr-1" icon="at" />

                    {{ editEmployee.workEmail }}
                  </span>
                </div>
                <div class="">
                  <span class="text-muted">
                    <font-awesome-icon class="mr-1" icon="phone-alt" />
                    {{
                      editEmployee.officeNumber
                        ? editEmployee.officeNumber
                        : "No Data Available"
                    }}
                  </span>
                </div>
              </div>
            </template>
          </card-row>
        </div>
      </div>

      <!--  Details-->
      <div
        class="card bg-transparent border-0 mt-4"
        v-if="editEmployee.firstName"
      >
        <page-title margin="m-0">
          <template #title>
            <span class="h4 text-primary">Details</span>
          </template>
        </page-title>

        <div class="collapse" id="collapseProfileDetails">
          <div class="my-4">
            <Form @submit="save" v-slot="meta" :validation-schema="schema">
              <!--            Row 1: First Name & Last Name-->
              <div class="row mt-3">
                <div class="col-12 mb-2">
                  <span class="h6 rounded-sm py-1 px-2 bg-secondary">
                    Personal Info:
                  </span>
                </div>
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">First name</label>
                  <input-field icon="id-card">
                    <template v-slot:input>
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        id="firstName"
                        :class="meta.errors.firstName ? 'is-invalid' : ''"
                        v-model="editEmployee.firstName"
                      />
                      <ErrorMessage name="firstName" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>

                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Last name</label>
                  <input-field icon="id-card">
                    <template v-slot:input>
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        :class="meta.errors.lastName ? 'is-invalid' : ''"
                        v-model="editEmployee.lastName"
                      />
                      <ErrorMessage name="lastName" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>

                <!--            Row 2: Gender &  Personal Email-->
                <div class="col-xs-12 col-md-6 form-group">
                  <label>Gender</label>

                  <Dropdown
                    size="lg"
                    :options="gender"
                    v-model="editEmployee.gender"
                    placeholder="Please select gender"
                    icon="venus-mars"
                    :fields="['id', 'value']"
                    :preSelected="Number(editEmployee.gender)"
                    clearable
                  />
                </div>

                <div class="col-xs-12 col-md-6 form-group">
                  <label>Personal email</label>
                  <input-field icon="at">
                    <template v-slot:input>
                      <Field
                        type="text"
                        id="personalEmail"
                        name="personalEmail"
                        v-model="editEmployee.personalEmail"
                        placeholder="Personal Email"
                        :class="meta.errors.personalEmail ? 'is-invalid' : ''"
                      />
                      <ErrorMessage
                        name="personalEmail"
                        class="invalid-feedback"
                      />
                    </template>
                  </input-field>
                </div>

                <!--              Row 3: Home number | Mobile Num-->
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required mb-0">Mobile number</label>
                  <small class="d-block text-muted mb-2"
                    >(Please enter full number including country code without
                    trailing 00 or + to ensure SMS delivery. e.g.
                    353861234567)</small
                  >
                  <input-field icon="phone-alt">
                    <template v-slot:input>
                      <Field
                        type="text"
                        name="mobileNumber"
                        id="mobileNumber"
                        placeholder="Mobile Number"
                        :class="meta.errors.mobileNumber ? 'is-invalid' : ''"
                        v-model="editEmployee.mobileNumber"
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        class="invalid-feedback"
                      />
                    </template>
                  </input-field>
                  <div v-if="false" class="d-inline-block mt-2">
                    <label
                      :class="`cursor-pointer rounded-sm m-0 px-2 py-1 border ${
                        editEmployee.smsNotification ? 'border-primary' : ''
                      }`"
                      for="flexCheckDefault"
                    >
                      <input
                        :disabled="!isAdmin"
                        type="checkbox"
                        value=""
                        v-model="editEmployee.smsNotification"
                        id="flexCheckDefault"
                      />
                      Receive SMS Notifications
                    </label>
                  </div>
                </div>

                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Home number</label>

                  <input-field icon="home">
                    <template v-slot:input>
                      <Field
                        type="text"
                        id="homeNumber"
                        name="homeNumber"
                        placeholder="Home Number"
                        :class="meta.errors.homeNumber ? 'is-invalid' : ''"
                        v-model="editEmployee.homeNumber"
                      />
                      <ErrorMessage
                        name="homeNumber"
                        class="invalid-feedback"
                      />
                    </template>
                  </input-field>
                </div>

                <!--            Row 4: Job Title | Dates-->
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Job Title</label>
                  <input-field icon="user">
                    <template v-slot:input>
                      <Field
                        type="text"
                        name="jobTitle"
                        placeholder="Job Title"
                        id="jobTitle"
                        :class="meta.errors.jobTitle ? 'is-invalid' : ''"
                        v-model="editEmployee.jobTitle"
                        :disabled="!canEdit"
                      />
                      <ErrorMessage name="jobTitle" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Job commencement date</label>
                  <Datepicker
                    uid="jobDate"
                    :inputClassName="
                      meta.errors.jobCommencementDate
                        ? 'dp-lg shadow-sm dp-invalid'
                        : 'dp-lg shadow-sm'
                    "
                    placeholder="Please select a date"
                    calendarCellClassName="dp-round-cell"
                    menuClassName="dp-menu"
                    autoApply
                    v-model="editEmployee.jobCommencementDate"
                    format="dd/MM/yyyy"
                    clearable
                    :disabled="!canEdit"
                    :enableTimePicker="false"
                  >
                    <template #input-icon>
                      <span class="pl-3">
                        <font-awesome-icon
                          class="text-primary"
                          icon="calendar-check"
                        />
                      </span>
                    </template>
                  </Datepicker>
                  <Field
                    type="hidden"
                    id="jobCommencementDate"
                    name="jobCommencementDate"
                    :class="meta.errors.jobCommencementDate ? 'is-invalid' : ''"
                    v-model="editEmployee.jobCommencementDate"
                  />
                  <ErrorMessage
                    name="jobCommencementDate"
                    class="invalid-feedback"
                  />
                </div>

                <!--            Row 5: DOB-->
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Date of Birth</label>
                  <Datepicker
                    uid="dob"
                    placeholder="Please select a date"
                    :inputClassName="
                      meta.errors.dob
                        ? 'dp-lg shadow-sm dp-invalid'
                        : 'dp-lg shadow-sm'
                    "
                    calendarCellClassName="dp-round-cell"
                    menuClassName="dp-menu"
                    autoApply
                    v-model="editEmployee.dob"
                    format="dd/MM/yyyy"
                    clearable
                    :enableTimePicker="false"
                  >
                    <template #input-icon>
                      <span class="pl-3">
                        <font-awesome-icon
                          class="text-primary"
                          icon="calendar-check"
                        />
                      </span>
                    </template>
                  </Datepicker>

                  <Field
                    type="hidden"
                    id="dob"
                    name="dob"
                    :class="meta.errors.dob ? 'is-invalid' : ''"
                    v-model="editEmployee.dob"
                  />
                  <ErrorMessage name="dob" class="invalid-feedback" />
                </div>

                <div class="col-12 mb-2 mt-3">
                  <span class="h6 rounded-sm py-1 px-2 bg-secondary">
                    Work Details:
                  </span>
                </div>
                <!--            Row 6 Work Location | Office Number-->
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="">Work Location</label>
                  <Dropdown
                    size="lg"
                    :options="workLocations"
                    v-model="editEmployee.workLocationId"
                    placeholder="Please select a location"
                    icon="map-pin"
                    :fields="['id', 'name']"
                    :preSelected="Number(editEmployee.workLocationId)"
                  />
                </div>
                <div class="col-xs-12 col-md-6 form-group">
                  <label>Office number</label>
                  <input-field icon="phone-alt">
                    <template v-slot:input>
                      <Field
                        type="text"
                        id="officeNumber"
                        name="officeNumber"
                        placeholder="Office Number"
                        :class="meta.errors.officeNumber ? 'is-invalid' : ''"
                        v-model="editEmployee.officeNumber"
                      />
                      <ErrorMessage
                        name="officeNumber"
                        class="invalid-feedback"
                      />
                    </template>
                  </input-field>
                </div>

                <!--            Row 7 emails | Departments-->
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Work email</label>
                  <input-field icon="at">
                    <template v-slot:input>
                      <Field
                        :disabled="true"
                        type="text"
                        id="workEmail"
                        name="workEmail"
                        placeholder="Work Email"
                        :class="meta.errors.workEmail ? 'is-invalid' : ''"
                        v-model="editEmployee.workEmail"
                      />
                      <ErrorMessage name="workEmail" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Department</label>
                  <Dropdown
                    size="lg"
                    :options="departments"
                    name="departmentId"
                    :input-class="meta.errors.departmentId ? 'is-invalid' : ''"
                    placeholder="Select a department"
                    icon="building"
                    v-model="editEmployee.departmentId"
                    :fields="['id', 'name']"
                    filterable
                    :preSelected="editEmployee.departmentId"
                    :disabled="!canEdit"
                  />
                  <!--            Extra Field - hidden for validation-->
                  <Field
                    name="departmentId"
                    id="departmentId"
                    :class="meta.errors.departmentId ? 'is-invalid' : ''"
                    type="hidden"
                    v-model="editEmployee.departmentId"
                  />
                  <ErrorMessage name="departmentId" class="invalid-feedback" />
                </div>

                <!--            Row 8-->
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Leaves Quota</label>
                  <input-field icon="calendar-check">
                    <template v-slot:input>
                      <Field
                        type="number"
                        name="leavesQuota"
                        placeholder="Leave Quota"
                        :class="meta.errors.leavesQuota ? 'is-invalid' : ''"
                        v-model="editEmployee.leavesQuota"
                        :disabled="!canEdit"
                      />
                      <ErrorMessage
                        name="leavesQuota"
                        class="invalid-feedback"
                      />
                    </template>
                  </input-field>
                </div>
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Carry Over Days</label>
                  <input-field icon="calendar-check">
                    <template v-slot:input>
                      <Field
                        type="number"
                        name="carryOverDays"
                        placeholder="Carry Days"
                        :class="meta.errors.carryOverDays ? 'is-invalid' : ''"
                        v-model="editEmployee.carryOverDays"
                        :disabled="!canEdit"
                      />
                      <ErrorMessage
                        name="carryOverDays"
                        class="invalid-feedback"
                      />
                    </template>
                  </input-field>
                </div>

                <div
                  v-if="editEmployee.manager != ' '"
                  class="col-xs-12 col-md-6 form-group"
                >
                  <label class="">Reports To</label>
                  <Dropdown
                    size="lg"
                    :options="managers"
                    v-model="editEmployee.managerId"
                    placeholder="Please select a manager"
                    icon="map-pin"
                    :fields="['id', 'firstName', 'lastName']"
                    :preSelected="Number(editEmployee.managerId)"
                    :disabled="!isAdmin"
                  />
                </div>

                <!--            Row 9-->
                <div class="col-12 form-group">
                  <label>Medical Conditions</label>
                  <textarea
                    name="medicalConditions"
                    id="medicalConditions"
                    placeholder="Medical condition (optional)"
                    v-model="editEmployee.medicalConditions"
                    rows="5"
                    :class="
                      meta.errors.medicalConditions
                        ? 'shadow-sm form-control is-invalid'
                        : 'shadow-sm form-control'
                    "
                  ></textarea>
                  <Field
                    name="medicalConditions"
                    :class="meta.errors.medicalConditions ? 'is-invalid' : ''"
                    type="hidden"
                    v-model="editEmployee.medicalConditions"
                  />
                  <ErrorMessage
                    name="medicalConditions"
                    class="invalid-feedback"
                  />
                </div>

                <!--            Address Part-->

                <!--            Row 10-->
                <div class="col-12 mb-2 mt-3">
                  <span class="h6 rounded-sm py-1 px-2 bg-secondary">
                    Address:
                  </span>
                </div>

                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Line 1</label>
                  <input-field icon="address-book">
                    <template v-slot:input>
                      <Field
                        type="text"
                        name="line1"
                        placeholder="Line 1"
                        :class="meta.errors.line1 ? 'is-invalid' : ''"
                        v-model="address.line1"
                      />
                      <ErrorMessage name="line1" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>
                <div class="col-xs-12 col-md-6 form-group">
                  <label>Line 2</label>
                  <input-field icon="address-book">
                    <template v-slot:input>
                      <Field
                        type="text"
                        name="line2"
                        placeholder="Line 2"
                        :class="meta.errors.line2 ? 'is-invalid' : ''"
                        v-model="address.line2"
                      />
                      <ErrorMessage name="line2" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>

                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Town</label>
                  <input-field icon="address-book">
                    <template v-slot:input>
                      <Field
                        type="text"
                        name="town"
                        placeholder="Town"
                        :class="meta.errors.town ? 'is-invalid' : ''"
                        v-model="address.town"
                      />
                      <ErrorMessage name="town" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">City</label>
                  <input-field icon="building">
                    <template v-slot:input>
                      <Field
                        type="text"
                        name="city"
                        placeholder="City"
                        :class="meta.errors.city ? 'is-invalid' : ''"
                        v-model="address.city"
                      />
                      <ErrorMessage name="city" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>

                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Country</label>
                  <Dropdown
                    size="lg"
                    :options="countries"
                    name="countryId"
                    :input-class="meta.errors.countryId ? 'is-invalid' : ''"
                    placeholder="Select a Country"
                    icon="globe"
                    v-model="address.countryId"
                    :fields="['id', 'name']"
                    filterable
                    :preSelected="address.countryId"
                  />
                  <!--            Extra Field - hidden for validation-->
                  <Field
                    name="countryId"
                    id="countryId"
                    :class="meta.errors.countryId ? 'is-invalid' : ''"
                    type="hidden"
                    v-model="address.countryId"
                  />
                  <ErrorMessage name="countryId" class="invalid-feedback" />
                </div>
                <div class="col-xs-12 col-md-6 form-group">
                  <label class="required">Post Code</label>
                  <input-field icon="map-pin">
                    <template v-slot:input>
                      <Field
                        type="text"
                        id="postCode"
                        name="postCode"
                        placeholder="Post Code"
                        :class="meta.errors.postCode ? 'is-invalid' : ''"
                        v-model="address.postCode"
                      />
                      <ErrorMessage name="postCode" class="invalid-feedback" />
                    </template>
                  </input-field>
                </div>
              </div>

              <div class="row my-3 justify-content-center">
                <div
                  class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
                >
                  <button
                    type="button"
                    class="btn-secondary btn-lg-cus"
                    @click.prevent="this.$router.back()"
                  >
                    Cancel
                  </button>
                </div>

                <div
                  class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
                >
                  <button
                    type="submit"
                    class="btn-primary btn-lg-cus"
                    :disabled="loading"
                  >
                    <span>Save </span>
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <div
                    v-if="message"
                    v-html="message"
                    class="alert alert-danger small"
                  ></div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div
          data-toggle="collapse"
          href="#collapseProfileDetails"
          role="button"
          aria-expanded="false"
          aria-controls="collapseProfileDetails"
          class="card-footer rounded py-2 hover-mod cursor-pointer border-0 text-center"
        >
          <span class="font-weight-bold text-muted"> Show/Hide Details </span>
        </div>
      </div>

      <!--  Emergency Contact-->
      <div
        class="card bg-transparent border-0 mt-4"
        v-if="emergencyContacts.length > 0"
      >
        <page-title margin="m-0">
          <template #title>
            <span class="h4 text-primary">Emergency Contact</span>
          </template>
        </page-title>

        <div class="collapse" id="collapseEmergencyContact">
          <div class="mt-3 mb-4">
            <div class="overlay" v-if="loadingContact">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <template v-if="emergencyContacts.length > 0">
              <draggable
                :class="loadingContact ? 'card-loading' : ''"
                v-model="emergencyContacts"
                :disabled="!enabled"
                v-bind="dragOptions"
                @update="checkMove"
                @start="drag = true"
                @end="drag = false"
                item-key="id"
              >
                <template #item="{ element, index }">
                  <card-row style="cursor: move">
                    <!--        Details-->
                    <template v-slot:details>
                      <div class="font-weight-bold">
                        {{ element.firstName }} {{ element.lastName }}
                        <span
                          class="badge badge-primary-light m-0 ml-2 px-1 py-0 text-primary-dark"
                          style="font-size: 18px; opacity: 0.7"
                          >#{{ index + 1 }}</span
                        >
                      </div>
                      <div class="text-muted">
                        {{ "Relationship: " + element.relationship }}
                      </div>
                      <div class="text-muted">
                        {{ "Mobile Number: " + element.mobilePhone }}
                      </div>
                    </template>

                    <!--        Actions-->
                    <template v-slot:actions>
                      <!--                    edit-->
                      <router-link
                        class="btn btn-sm btn-primary px-2"
                        :to="{
                          name: 'Edit Emergency Contact',
                          path: '/admin/emergencycontact/edit',
                          params: { emergencyContactId: element.id },
                        }"
                      >
                        <font-awesome-icon class="mr-1" icon="pencil-alt" />
                        Edit
                      </router-link>
                      <!--                    Cancel-->
                      <button
                        class="btn btn-sm btn-danger ml-1"
                        @click.prevent="deleteEmergencyContact(element.id)"
                      >
                        <font-awesome-icon class="mr-1" icon="trash" />
                        Delete
                      </button>
                    </template>
                  </card-row>
                </template>
              </draggable>
            </template>
            <template v-else>
              <div class="card mb-3 shadow-sm">
                <div class="card-body border-0 rounded py-3">
                  <span class="h6">No Data Available</span>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div
          data-toggle="collapse"
          href="#collapseEmergencyContact"
          role="button"
          aria-expanded="false"
          aria-controls="collapseEmergencyContact"
          class="card-footer rounded py-2 hover-mod cursor-pointer border-0 text-center"
        >
          <span class="font-weight-bold text-muted"> Show/Hide Details </span>
        </div>
      </div>

      <!--  Note-->
      <div class="card bg-transparent border-0 mt-4" v-if="notes.length > 0">
        <page-title margin="m-0">
          <template #title>
            <span class="h4 text-primary">Notes</span>
          </template>
        </page-title>

        <div class="collapse" id="collapseNote">
          <div class="mt-3 mb-4">
            <div v-for="item in notes" v-bind:key="item.id">
              <card-row :avatar="false">
                <!--        Details-->
                <template v-slot:details>
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-primary mr-2">
                      {{ item.name }}
                    </div>

                    <span v-if="item.status != 'Employee'">
                      <span
                        :class="[
                          item.status === 'Incident'
                            ? 'badge badge-danger badge-pill'
                            : item.status === 'Public'
                            ? ' badge badge-info badge-pill'
                            : 'badge badge-success badge-pill',
                        ]"
                      >
                        {{ item.status }}
                      </span>
                    </span>
                  </div>

                  <div class="">
                    <footer class="blockquote-footer">
                      Note Added On:
                      <cite title="Source Title">{{
                        useFormatDate(item.createdOn)
                      }}</cite>
                    </footer>
                  </div>

                  <div class="my-1">
                    <span>{{ item.detail }}</span>
                  </div>
                </template>
              </card-row>
            </div>
          </div>
        </div>

        <div
          data-toggle="collapse"
          href="#collapseNote"
          role="button"
          aria-expanded="false"
          aria-controls="collapseNote"
          class="card-footer rounded py-2 hover-mod cursor-pointer border-0 text-center"
        >
          <span class="font-weight-bold text-muted"> Show/Hide Details </span>
        </div>
      </div>

      <!--  Goals-->
      <div class="card bg-transparent border-0 mt-4" v-if="goals.length > 0">
        <page-title margin="m-0">
          <template #title>
            <span class="h4 text-primary">Goals</span>
          </template>
        </page-title>

        <div class="collapse" id="collapseGoal">
          <div class="mt-3 mb-4">
            <div v-for="item in goals" v-bind:key="item.id">
              <card-row :avatar="false">
                <!--        Details-->
                <template v-slot:details>
                  <div class="font-weight-bold text-primary">
                    {{ item.name }}
                  </div>
                  <div class="">
                    <span class="">
                      {{ item.description }}
                    </span>
                  </div>
                  <div class="d-block d-md-flex">
                    <div class="mr-2">
                      <span class="mr-1"> Set Date: </span>
                      <span class="text-primary">
                        {{ useFormatDate(item.setDate) }}
                      </span>
                    </div>
                    <div>
                      <span class="mr-1"> Due Date: </span>
                      <span class="text-primary">
                        {{ useFormatDate(item.dueDate) }}
                      </span>
                    </div>
                  </div>
                </template>
              </card-row>
            </div>
          </div>
        </div>

        <div
          data-toggle="collapse"
          href="#collapseGoal"
          role="button"
          aria-expanded="false"
          aria-controls="collapseGoal"
          class="card-footer rounded py-2 mb-3 hover-mod cursor-pointer border-0 text-center"
        >
          <span class="font-weight-bold text-muted"> Show/Hide Details </span>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="card-body shadow-sm border rounded mt-3">
        <loading-component></loading-component>
      </div>
    </template>
  </div>

  <!--  Modals-->
  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
</template>

<script src="./edit-employee.ts" />

<style scoped></style>
