import { Options, Vue } from "vue-class-component";
import { IEmployeeNewEdit, IEmployeeProfile } from "@/types/Employee";
import { IDepartment } from "@/types/Department";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { IGender } from "@/types/Employee";
import { IAddress, IWorkLocation } from "@/types/Address";
import { ICountry } from "@/types/Address";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import { ISetting } from "@/types/Setting";
import DropdownComponent from "@/components/DropdownComponent.vue";
import Datepicker from "@vuepic/vue-datepicker";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import AuthStore from "@/store/auth-store";
import { IEmergencyContact } from "@/types/EmergencyContact";
import draggable from "vuedraggable";
import { INote } from "@/types/Note";
import { IGoal } from "@/types/Goal";
import { formatDate_h, notify, useFormatDate } from "@/services/helpers";
import moment from "moment/moment";
import ProfileImage from "@/views/employees/ProfileImage.vue";

@Options({
  name: "EditEmployee",
  components: {
    ProfileImage,
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    Dropdown: DropdownComponent,
    Datepicker,
    ConfirmationModal,
    draggable,
  },
  data: function () {
    return {
      schema: yup.object().shape({
        firstName: yup
          .string()
          .required("Please provide your first name.")
          .max(75, "Must not exceed 75 characters in length."),
        lastName: yup
          .string()
          .required("Please provide your last name.")
          .max(75, "Must not exceed 75 characters in length."),
        maritalStatus: yup
          .string()
          .max(20, "Must not exceed 20 characters in length."),
        mobileNumber: yup
          .string()
          .required("Please enter your mobile number.")
          .test(
            "minLength",
            "Must be at least 7 characters in length.",
            function (value) {
              return !value || value.length >= 7;
            }
          )
          .max(14, "Must not exceed 14 characters in length."),
        officeNumber: yup
          .string()
          .test(
            "minLength",
            "Must be at least 7 characters in length.",
            function (value) {
              return !value || value.length >= 7;
            }
          )
          .max(14, "Must not exceed 14 characters in length."),
        homeNumber: yup
          .string()
          .required("Please provide your home number.")
          .test(
            "minLength",
            "Must be at least 7 characters in length.",
            function (value) {
              return !value || value.length >= 7;
            }
          )
          .max(14, "Must not exceed 14 characters in length."),
        workEmail: yup
          .string()
          .required("Please provide your work email")
          .email("Please provide a valid email address."),
        personalEmail: yup
          .string()
          .email("Please provide a valid email address."),
        departmentId: yup
          .number()
          .moreThan(0, "Please select a department from the list.")
          .required("Please select a department from the list."),
        jobTitle: yup
          .string()
          .required("Please provide your job title.")
          .max(50, "Must not exceed 50 characters in length."),
        medicalConditions: yup
          .string()
          .max(200, "Must not exceed 200 characters in length."),
        line1: yup
          .string()
          .required("Please provide the first line of your address (Line 1).")
          .max(200, "Must not exceed 200 characters in length."),
        line2: yup
          .string()
          .max(200, "Must not exceed 200 characters in length."),
        town: yup
          .string()
          .required("Please provide the name of your town.")
          .max(100, "Must not exceed 100 characters in length."),
        city: yup
          .string()
          .required("Please provide the name of your city.")
          .max(50, "Must not exceed 50 characters in length."),
        countryId: yup
          .number()
          .moreThan(0, "Please select a country from the list.")
          .required("Please select a country from the list.")
          .max(15, "Must not exceed 15 characters in length."),
        postCode: yup
          .string()
          .required("Please provide your postal code.")
          .max(50, "Must not exceed 50 characters in length."),
        leavesQuota: yup
          .number()
          .min(
            0,
            "Please make sure that the input value is greater than or equal to 0."
          )
          .max(50, "Please ensure that the input value does not exceed 50."),
        carryOverDays: yup
          .number()
          .min(
            0,
            "Please make sure that the input value is greater than or equal to 0."
          )
          .max(50, "Please ensure that the input value does not exceed 50."),
        dob: yup
          .date()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            "Please ensure that the date of birth (DOB) age is equal to or greater than 18 years."
          )
          .required("Please provide date of birth (DOB)."),
        jobCommencementDate: yup
          .date()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .required("Please provide the job commenced date."),
      }),
    };
  },
  props: {},
  computed: {
    dragOptions() {
      return {
        animation: 400,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
})
export default class EditEmployee extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  departments: IDepartment[] = [];
  workLocations: IWorkLocation[] = [];
  countries: ICountry[] = [];
  fields: any = { text: "name", value: "id" };
  managers: IEmployeeProfile[] = [];

  gender: IGender[] = [
    { id: 0, value: "Male" },
    { id: 1, value: "Female" },
    { id: 2, value: "Prefer not to say" },
  ];
  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  rolesEnum = {
    admin: "Admin",
    manager: "Manager",
    orgAdmin: "Org Admin",
  };

  message = "";
  authId = AuthStore.getTokenData().EmployeeId;
  authRole = AuthStore.getTokenData().Roles;
  isAdmin =
    AuthStore.getTokenData().Roles == this.rolesEnum.admin ||
    AuthStore.getTokenData().Roles == this.rolesEnum.orgAdmin;
  canEdit = false;

  editEmployee: IEmployeeNewEdit = {
    id: 0,
    firstName: "",
    lastName: "",
    gender: "",
    homeNumber: "",
    maritalStatus: "",
    medicalConditions: "",
    mobileNumber: "",
    smsNotification: false,
    officeNumber: "",
    personalEmail: "",
    workEmail: "",
    department: "",
    departmentId: 0,
    workLocationId: 0,
    jobTitle: "",
    isActive: false,
    departmentName: "",
    manager: "",
    managerId: 0,
    addressId: 0,
    leavesQuota: 0,
    carryOverDays: 0,
    dob: "",
    jobCommencementDate: "",
    role: "",
  };
  employeeId = 0;

  formatDate = formatDate_h;

  useFormatDate = useFormatDate;

  $refs!: {
    confirmDialogue: HTMLFormElement;
  };

  crumbs: any = ["Dashboard", "Employees", "Edit Employee"];
  address: IAddress = {
    id: 0,
    line1: "",
    line2: "",
    town: "",
    city: "",
    postCode: "",
    countryId: 0,
  };

  // Emergency Contact
  emergencyContacts: IEmergencyContact[] = [];
  loadingContact = false;
  enabled = true;

  // Notes
  notes: INote[] = [];

  // Goals
  goals: IGoal[] = [];

  loading = false;

  // For emergency contact
  async checkMove() {
    const list = this.emergencyContacts.map((item, index) => {
      return {
        id: item.id,
        priority: index + 1,
      };
    });

    this.loadingContact = true;
    await this.workForceService
      .post(`/EmergencyContact/updatePriority`, list)
      .then(() => {
        this.loadingContact = false;
      });
  }

  async employeeBookLeave(empId: number, name: string) {
    this.$router.push({
      name: "Leaves",
      params: { id: btoa(empId.toString()), name: btoa(name) },
    });
  }

  canInviteOrEdit(loggedInRole: string, inviteeRole: string) {
    if (
      loggedInRole == this.rolesEnum.manager &&
      (inviteeRole == this.rolesEnum.orgAdmin ||
        inviteeRole == this.rolesEnum.admin)
    ) {
      return false;
    }
    return true;
  }

  emergencyContactId = 0;
  async deleteEmergencyContact(id: number) {
    this.emergencyContactId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Delete Contact?",
      message:
        "Are you sure you want to delete this Emergency Contact? It cannot be undone.",
      okButton: "Delete",
      cancelButton: "Cancel",
      theme: "danger",
      type: "confirmation",
    });
    if (modal) {
      this.workForceService
        .delete(`/emergencycontact/${this.emergencyContactId}`)
        .then((response: any) => {
          if (!response.isError) {
            notify("Contact deleted successfully.", "Success", "success");
          } else {
            notify(response.errors, "Error", "danger");
          }
          this.initialize();
          this.emergencyContactId = 0;
        });
    } else {
      this.emergencyContactId = 0;
      return false;
    }
  }

  calculateAge(date: Date) {
    const today = new Date();
    const birthDate = new Date(date);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  async save() {
    this.loading = true;
    this.message = "";
    if (
      new Date(this.editEmployee.dob) >
      new Date(this.editEmployee.jobCommencementDate)
    ) {
      notify(
        "Please ensure that the date of birth (DOB) is earlier than the job commencement date.",
        "Attention",
        "danger"
      );
      this.loading = false;
      return;
    } else if (this.calculateAge(new Date(this.editEmployee.dob)) < 18) {
      notify(
        "Please ensure that the date of birth (DOB) age is equal to or greater than 18 years.",
        "Attention",
        "danger"
      );
      this.loading = false;
      return;
    }
    const addressId =
      this.editEmployee.addressId > 0
        ? this.editEmployee.addressId.toString()
        : "";
    this.editEmployee.carryOverDays = parseInt(
      this.editEmployee.carryOverDays.toString()
    );
    this.editEmployee.leavesQuota = parseInt(
      this.editEmployee.leavesQuota.toString()
    );

    this.editEmployee.gender = String(this.editEmployee.gender);
    this.editEmployee.dob = this.formatDate(this.editEmployee.dob);
    this.editEmployee.jobCommencementDate = this.formatDate(
      this.editEmployee.jobCommencementDate
    );

    await this.workForceService
      .postOrPut<void>(
        `/address/${addressId}`,
        this.address as IAddress,
        addressId
      )
      .then((response: any) => {
        if (!response.isError) {
          if (this.editEmployee.addressId == 0) {
            this.editEmployee.addressId = response.content.id;
          }

          this.workForceService
            .postOrPut<void>(
              `/employee/${this.editEmployee.id}`,
              this.editEmployee as IEmployeeNewEdit,
              this.editEmployee.id.toString()
            )
            .then((response: any) => {
              if (!response.isError) {
                notify("Employee updated successfully.", "Success", "success");
                this.loading = false;
              } else {
                this.loading = false;
                notify(response.errors, "Error", "danger");
              }
            });
        } else {
          this.loading = false;
          notify(response.errors, "Error", "danger");
        }
      });
  }

  canDeactivate(role: string, id: number) {
    if (
      this.authRole == this.rolesEnum.admin ||
      (this.authRole == this.rolesEnum.orgAdmin &&
        role != this.rolesEnum.orgAdmin &&
        id != this.authId)
    ) {
      return true;
    }
    return false;
  }

  async inActiveEmployee(id: number) {
    this.employeeId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Deactivate Employee?",
      message: "Are you sure you want to deactivate this employee?",
      okButton: "Deactivate",
      cancelButton: "Cancel",
      icon: "warning",
      type: "confirmation",
    });
    // If you throw an error, the method will terminate here unless you surround it wil try/catch
    if (modal) {
      this.workForceService
        .delete(`/employee/${this.employeeId}`)
        .then((res: any) => {
          if (!res.isError) {
            this.initialize();
            notify("Employee deactivated successfully.", "Success", "success");
          } else {
            notify(res.errors, "Error", "danger");
          }
        });
      this.employeeId = 0;
    } else {
      this.employeeId = 0;
      return false;
    }
  }

  async reActiveEmployee(id: number) {
    this.employeeId = id;
    const modal = await this.$refs.confirmDialogue.show({
      title: "Reactivate Employee?",
      message: "Are you sure you want to reactivate this employee?",
      okButton: "Reactivate",
      cancelButton: "Cancel",
      icon: "warning",
      type: "confirmation",
    });
    // If you throw an error, the method will terminate here unless you surround it wil try/catch
    if (modal) {
      this.workForceService
        .postOrPut(
          `/employee/activate/${this.employeeId}`,
          null,
          this.employeeId.toString()
        )
        .then((res: any) => {
          if (!res.isError) {
            this.initialize();
            notify("Employee reactivated successfully.", "Success", "success");
          } else {
            notify(res.errors, "Error", "danger");
          }
        });
      this.employeeId = 0;
    } else {
      this.employeeId = 0;
      return false;
    }
  }

  async created() {
    this.canEdit =
      (AuthStore.getTokenData().Roles == this.rolesEnum.admin ||
        AuthStore.getTokenData().Roles == this.rolesEnum.manager ||
        AuthStore.getTokenData().Roles == this.rolesEnum.orgAdmin) &&
      this.$route.params.id !== this.authId;

    await this.initialize();
  }

  async initialize() {
    this.loading = true;

    // Load Employee
    const employeeId = this.$route.params.id;
    await this.workForceService
      .get<IEmployeeNewEdit>(`/employee/${employeeId}`, false)
      .then((response: any) => {
        if (
          response.content &&
          this.canInviteOrEdit(this.authRole, response.content.role)
        ) {
          this.editEmployee = response.content;
        } else {
          this.$router.back();
        }
        if (this.editEmployee.leavesQuota == 0) {
          this.workForceService
            .get<ISetting[]>(`/settings/LeavesQuota`, false)
            .then((response: any) => {
              this.editEmployee.leavesQuota = parseInt(response.content.value);
            });
        }
        if (this.editEmployee.carryOverDays == 0) {
          this.workForceService
            .get<ISetting[]>(`/settings/CarryOverDays`, false)
            .then((response: any) => {
              this.editEmployee.carryOverDays = parseInt(
                response.content.value
              );
            });
        }
        if (this.editEmployee.addressId > 0) {
          this.workForceService
            .get<IAddress>(`/address/${this.editEmployee.addressId}`, false)
            .then((response: any) => {
              this.address = response.content;
            });
        }
      });

    await this.workForceService
      .get<IDepartment[]>(
        `/departments?IsActive=true&PageIndex=0&PageSize=100`,
        false
      )
      .then((response: any) => {
        this.departments = response.content.items;
      });

    await this.workForceService
      .get<IWorkLocation[]>(`/worklocations`, false)
      .then((response: any) => {
        this.workLocations = response.content;
      });

    await this.workForceService
      .get<IEmployeeProfile[]>(`/employee/managers`, false)
      .then((response: any) => {
        this.managers = response.content;
      });

    await this.workForceService
      .get<ICountry[]>(`/countries`, false)
      .then((response: any) => {
        this.countries = response.content;
      });

    // EmergencyContact
    await this.workForceService
      .get<IEmergencyContact[]>(
        `/emergencycontact?EmployeeId=${employeeId}`,
        false
      )
      .then((response: any) => {
        this.emergencyContacts = response.content.items;
      });

    // Notes
    await this.workForceService
      .get<INote[]>(`/notes?EmployeeId=${employeeId}`, false)
      .then((response: any) => {
        this.notes = response.content.items;
      });

    // Goals
    await this.workForceService
      .get<IGoal[]>(`/goals?EmployeeId=${employeeId}`, false)
      .then((response: any) => {
        this.goals = response.content.items;
      });
    this.loading = false;
  }
}
